import { ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import {
	Badge,
	Button,
	Container,
	Dropdown,
	Image,
	Nav,
	Navbar,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Logo from "../../../../assets/images/logo.svg";
import profileImg from "../../../../assets/images/profile-image-dummy.jpg";
import appConfig from "../../../../helpers/config";
import { baseRoutes } from "../../../../routes/base-routes";
import { persistor } from "../../../../store";
import { logoutAction } from "../../../../store/reducer/auth";
import LogoutHandleModal from "./HandleLogoutModal";
import "./header.scss";
export const Header = ({ toggleSidebarButton, isActiveSidebar }: any) => {
	const userInfo = useSelector((state: any) => state.auth.userInfo);
	const clientName = userInfo?.client_name ? userInfo?.client_name : "User";
	const [profileImage, setProfileImage] = useState(profileImg);
	const [fullName, setFullName] = useState(clientName);
	const [openLogoutModalGlobalState, setOpenLogoutModalGlobalState] = useState(false);
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
	const navigate = useNavigate();
	const parameter = useParams();

	const notificationData = [
		{
			id: '1',
			title: "New Case Created",
			sub_title: "[EB-2 NIW / CP (11772-001) [KBCAW30216-1] ",
			date_time: "07/21/2023 11:39 AM",
		},
		{
			id: '2',
			title: "Case Assigned to",
			sub_title: "John Smith",
			date_time: "07/21/2023 11:35 AM",
		},
		{
			id: '3',
			title: "All documents received for",
			sub_title: "All documents received for [EB-2 NIW / CP (11772-001) [KBCAW30216-1]",
			date_time: "07/21/2023 11:35 AM",
		},
		{
			id: '4',
			title: "New Case Created",
			sub_title: "[EB-2 NIW / CP (11772-001) [KBCAW30216-1] ",
			date_time: "07/21/2023 11:39 AM",
		},
		{
			id: '5',
			title: "Case Assigned to",
			sub_title: "John Smith",
			date_time: "07/21/2023 11:35 AM",
		},
		{
			id: '6',
			title: "All documents received for",
			sub_title: "All documents received for [EB-2 NIW / CP (11772-001) [KBCAW30216-1]",
			date_time: "07/21/2023 11:35 AM",
		},
	]

	useEffect(() => {
		function storageEventHandler(event: any) {
			if (window.localStorage.getItem("checklogout") === 'forceLogout') {
				console.log("storageEventHandler", event);
				localStorage.removeItem("checklogout");
				handleLogout(event);
			}
		}
		// Hook up the event handler
		window.addEventListener("storage", storageEventHandler);
		return () => {
			// Remove the handler when the component unmounts
			window.removeEventListener("storage", storageEventHandler);
		};
	}, [])

	const handleLogout = async (e: any) => {
		dispatch(logoutAction()).then(async (data) => {
			await persistor.purge();
			navigate(`${appConfig.basePath}/login`);
		 });
		
	}
	const handleCloseModal = () => {
		setOpenLogoutModalGlobalState(false);
	}
	return (
		<>
			<Navbar
				bg="white"
				variant="light"
				className="py-0 px-md-1 shadow-sm z-2 theme-top-header"
				data-bs-theme="light"
			>
				<Container fluid className="h-100">
					<Button
						onClick={toggleSidebarButton}
						variant="link"
						className="align-items-center d-flex d-xl-none justify-content-center me-3 navMenuBtn p-0 py-3"
					>
						<span
							className={`bg-primary d-inline-block menuTrigger position-relative text-center ${isActiveSidebar ? "active" : ""}`}
						></span>
					</Button>
					<Link to={baseRoutes.dashboard.path} className="me-3">
						<Image
							className="img-fluid"
							src={Logo}
							alt="Logo"
							width={192}
							height={35}
						/>
					</Link>
					<Nav className="ms-auto align-items-center">
						<Dropdown className="notificationDropdown me-lg-3 me-1">
							<Dropdown.Toggle
								variant="link"
								id="dropdown-notification"
								className="fs-14 fw-semibold text-decoration-none position-relative rounded-circle d-inline-flex align-items-center justify-content-center text-body p-2"
							>
								<FaBell size={22} />
								<Badge
									bg="danger"
									pill
									className="align-items-center border border-white customBadge d-flex fs-11 fw-normal justify-content-center mt-1 p-1 position-absolute rounded-pill start-100"
								>
									{notificationData.length}
									<span className="visually-hidden">Unread Notifications</span>
								</Badge>
							</Dropdown.Toggle>

							<Dropdown.Menu align="end" className="shadow border-0 mt-3 p-1 theme-notification-menu">
								<ul className="list-unstyled p-1 theme-custom-scrollbar overflow-auto">
									{notificationData.map((dataItems: any) => {
										const { id, title, sub_title, date_time } = dataItems;
										return (
											<li key={id} className="fs-15 pb-2 mb-2 border-bottom">
												<div>{title}</div>
												<div className="fw-bold">{sub_title}</div>
												<div className="fs-12 text-body-tertiary">{date_time}</div>
											</li>
										)
									})}
								</ul>
								<Dropdown.Item
									as={NavLink}
									to={"#"}
									className="p-1 fs-12 fw-semibold bg-transparent text-center text-light-emphasis"
									disabled={true}
								>
									View All
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown className="profileDropdown ms-1">
							<Dropdown.Toggle
								variant="link"
								id="dropdown-profile"
								className="border-0 fw-semibold text-decoration-none p-0 text-body"
							>
								<Image
									className="object-fit-cover rounded-circle border border-light-subtle"
									src={profileImage}
									alt="Profile Image"
									width={40}
									height={40}
								/>
								<div className="align-middle fs-6 d-none d-md-inline-block ms-1 px-2 text-truncate max-w-150">
									{fullName}
								</div>
								<IoIosArrowDown size={16} />
							</Dropdown.Toggle>
							<Dropdown.Menu align="end" className="border-0 shadow">
								<Dropdown.Item
									as={NavLink}
									to={baseRoutes.changePassword.path}
								>
									Change Password
								</Dropdown.Item>
								<Dropdown.Item onClick={(e) => handleLogout(e)}>
									Logout
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav>
				</Container>
			</Navbar>
			<LogoutHandleModal
				show={openLogoutModalGlobalState}
				handleClose={handleCloseModal}
			/>
		</>
	);
};
