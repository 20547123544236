import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { GoArrowLeft } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Logo from "../../assets/images/logo-white.svg";
import ResetPasswordBanner from "../../assets/images/reset-password-banner.jpg";
import CommonFormikComponent from '../../components/common/CommonFormikComponent';
import ThemeInputPassword from "../../components/common/InputPassword";
import Loader from "../../components/common/Loader";
import { baseRoutes } from "../../routes/base-routes";
import { ResetPasswordSchema } from "../../validations/auth-schema/reset-password";
import { useEffect, useRef } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { resetPasswordAction } from "../../store/reducer/auth";
import CryptoJS from 'crypto-js';
import appConfig from "../../helpers/config";
export const ResetPassword = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isLoading = useSelector((state: any) => state.auth.loading);
    const formRef:any = useRef();
    // Initial Values
    const initialValues: Record<string, any> = {
        password: '',
        confirm_password: '',
    };
    const parameter = useParams();
    
    const handleSubmit = (values: Record<string, any>, actions: any) => {
        actions.setSubmitting(false);
        const encryptionKey = appConfig.passwordEncryptionKey;
        // Encrypt
        let cipherPassword = CryptoJS.AES.encrypt(values.password, encryptionKey).toString()
        const payloadData = {token: parameter?.token, password: cipherPassword};
        dispatch(resetPasswordAction(payloadData)).then(()=>{
            formRef.current.setFieldValue("password", "");
            formRef.current.setFieldValue("confirm_password", "");
            formRef.current.setTouched({
                password: false,
                confirm_password: false
            })
        })
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <Card className="border-0 overflow-auto min-vh-100 w-100 text-center z-1 py-4 px-3 p-md-5 rounded-0">
                <div className="theme-auth-header pt-2 pb-4 w-75 mx-auto">
                    <Link to="/" className="d-inline-block">
                        <Image src={Logo} alt="Logo" width={330} height={60} fluid />
                    </Link>
                </div>
                <Row className="justify-content-center g-0 mt-md-3">
                    <Col xs={12}>
                        <Card className="px-4 pb-4 px-md-5 pb-md-5 text-start rounded-3 border-0 mx-auto max-w-450">
                            <h1 className="fs-4 fw-semibold my-2 mt-md-4 pt-3 pt-md-4 text-center text-primary">Reset Password</h1>
                            <p className="text-center mb-4">Please enter your password to reset <br className="d-sm-block d-none" /> the&nbsp;password.</p>
                            <CommonFormikComponent
                                validationSchema={ResetPasswordSchema}
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                innerRef={formRef}
                            >
                                {(formikProps) => (
                                    <>
                                        <Form.Group className="mb-3">
                                            <ThemeInputPassword
                                                inputClassName="theme-inputtext-lg"
                                                toggleMask={true}
                                                controlId="password"
                                                label='New Password*'
                                                handleBlur={formikProps.handleBlur}
                                                handleChange={formikProps.handleChange}
                                                value={formikProps.values.password}
                                                touched={formikProps.touched.password}
                                                errorsField={formikProps.errors.password}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-4 pb-1">
                                            <ThemeInputPassword
                                                inputClassName="theme-inputtext-lg"
                                                toggleMask={true}
                                                controlId="confirm_password"
                                                label='Confirm New Password*'
                                                handleBlur={formikProps.handleBlur}
                                                handleChange={formikProps.handleChange}
                                                value={formikProps.values.confirm_password}
                                                touched={formikProps.touched.confirm_password}
                                                errorsField={formikProps.errors.confirm_password}
                                            />
                                        </Form.Group>
                                        <Button className="w-100 btn-lg mt-2 fw-normal" type="submit">
                                            Submit
                                        </Button>
                                    </>
                                )}
                            </CommonFormikComponent>
                        </Card>
                        <div className="my-4 py-1">
                            <Link to={baseRoutes.login.path} className="d-inline-flex align-items-center gap-2 text-decoration-underline small text-white">
                                <GoArrowLeft size={20} />
                                <span>{baseRoutes.login.pathName}</span>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Card.ImgOverlay className="p-0 pe-none user-select-none z-n1 rounded-0">
                    <Card.Img
                        src={ResetPasswordBanner}
                        alt="Reset Password Banner"
                        className="w-100 h-100 object-fit-cover rounded-0"
                    />
                </Card.ImgOverlay>
            </Card>
        </>
    );
};
